import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Icon from '../UI/Icon';
import IMAGES from '../../assets/images';

function Navigation(props) {
  const { i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const [navIsOpen, setnavIsOpen] = useState(false);
  const [lang, setLang] = useState(getLanguage());
  const [langDropdownActive, setLangDropdownActive] = useState(false);
  const classes = props.classes !== undefined ? props.classes : '';
  const overlayClasses = navIsOpen ? 'overlay' : 'overlay hidden';
  const navOpenClass = navIsOpen ? 'nav-open' : '';
  const stickyNavClasses =
    props.heroIsVisible === false ? 'sticky fadeInTop' : '';

  const navButtonHandler = () => {
    setnavIsOpen(navIsOpen => !navIsOpen);
    setLangDropdownActive(false);
  };

  const logoLinkHandler = () => {
    if (window.location.pathname === '/')
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
  };

  const closeNav = () => setnavIsOpen(false);
  const closeLangDropdown = () => setLangDropdownActive(false);

  useEffect(() => {
    if (navIsOpen) {
      window.addEventListener('scroll', closeNav);
      return;
    }
    window.removeEventListener('scroll', closeNav);
  }, [navIsOpen]);

  useEffect(() => {
    if (langDropdownActive) {
      window.addEventListener('scroll', closeLangDropdown);
    }
    window.removeEventListener('scroll', closeNav);
  }, [langDropdownActive]);

  return (
    <>
      {ReactDOM.createPortal(
        <div className={overlayClasses} onClick={closeNav}></div>,
        document.querySelector('body')
      )}
      <header
        className={`header ${classes} ${navOpenClass} ${stickyNavClasses}`}
      >
        <nav className="nav">
          <Link to="/" className="brand" onClick={logoLinkHandler}>
            <img
              src={IMAGES.logoSvg}
              alt="Kuddly logo"
              className="brand__logo"
            />
            <p className="brand__text">Kuddly</p>
          </Link>

          <div className="nav__group">
            <ul
              className="nav__list"
              lang={i18n.language || window.localStorage.i18nextLng || ''}
            >
              {props.navLinks}
            </ul>
            <div className="dropdown-container">
              <button
                className={`dropdown__toggle ${
                  langDropdownActive ? 'dropdown__toggle--active' : ''
                }`}
                type="button"
                onClick={() => setLangDropdownActive(prev => !prev)}
              >
                {lang.includes('el') ? (
                  <img
                    className="language__icon "
                    src={IMAGES.flagGrPng}
                    alt="Greek language option"
                  />
                ) : (
                  <img
                    className="language__icon "
                    src={IMAGES.flagUkPng}
                    alt="English language option"
                  />
                )}
                <Icon classes="dropdown__icon" icon="caret-down" />
              </button>
              <ul className="dropdown">
                <li>
                  <button
                    className="language"
                    onClick={() => {
                      i18n.changeLanguage('en');
                      setLangDropdownActive(false);
                      setLang('en');
                    }}
                    type="submit"
                  >
                    <img
                      className="language__icon"
                      src={IMAGES.flagUkPng}
                      alt="English language option"
                    />
                    English
                  </button>
                </li>
                <li>
                  <button
                    className="language"
                    onClick={() => {
                      i18n.changeLanguage('el');
                      setLangDropdownActive(false);
                      setLang('el');
                    }}
                    type="submit"
                  >
                    <img
                      className="language__icon"
                      src={IMAGES.flagGrPng}
                      alt="Greek language option"
                    />
                    Ελληνικά
                  </button>
                </li>
              </ul>
            </div>

            <button className="nav__btn" onClick={navButtonHandler}>
              <svg className="nav__btn--icon" name="menu">
                <use href="/sprite.svg#icon-menu"></use>
              </svg>
              <svg className="nav__btn--icon" name="close">
                <use href="/sprite.svg#icon-close"></use>
              </svg>
            </button>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Navigation;
