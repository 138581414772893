import { createContext, useState } from 'react';

const FaqContext = createContext(null);

const FaqContextProvider = props => {
  const [activeFaq, setActiveFaq] = useState(null);

  return (
    <FaqContext.Provider value={{ activeFaq, setActiveFaq }}>
      {props.children}
    </FaqContext.Provider>
  );
};

export { FaqContext, FaqContextProvider };
