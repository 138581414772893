import IMAGES from '../../assets/images';

function DownloadLinks({ classes, aos }) {
  return (
    <div
      className={classes}
      data-aos={aos && aos.type}
      data-aos-offset={aos && aos.offset}
    >
      <a
        href="https://apps.apple.com/gr/app/kuddly/id6450275506"
        target="_blank"
        rel="noreferrer"
        className="download-link ios"
      >
        <img
          src={IMAGES.downloadAppleSvg}
          alt="Download on Apple Store button"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=gr.rabbyte.kuddly"
        target="_blank"
        rel="noreferrer"
        className="download-link android"
      >
        <img
          src={IMAGES.downloadAndroidSvg}
          alt="Download on Google Play button"
        />
      </a>
    </div>
  );
}

export default DownloadLinks;
