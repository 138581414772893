import { useState, useLayoutEffect } from 'react';

const isBetween = (val, floor, ceil) => val >= floor && val <= ceil;

const useScrollHighlight = ({ ids, offset }) => {
  const [activeId, setActiveId] = useState('');

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = ids
        .map(id => {
          const element = document.getElementById(id);

          const style = getComputedStyle(element);

          const scrollMargin = parseInt(style.scrollMarginTop);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = rect.top + scroll - offset - scrollMargin;
          const bottom = rect.bottom + scroll - offset;

          return { id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      setActiveId(position?.id || '');
    };

    listener();

    window.addEventListener('resize', listener);
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('resize', listener);
      window.removeEventListener('scroll', listener);
    };
  }, [ids, offset]);

  return activeId;
};

export default useScrollHighlight;
