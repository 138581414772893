import Icon from './Icon';

function IconTextLink({
  linkClass,
  linkHref,
  linkName,
  linkText,
  targetBlank = false,
  iconClass,
  iconId,
}) {
  return (
    <a
      href={linkHref}
      className={linkClass}
      name={linkName}
      target={targetBlank ? '_blank' : ''}
      rel={targetBlank ? 'noreferrer' : ''}
    >
      <Icon icon={iconId} classes={iconClass} />
      {linkText}
    </a>
  );
}

export default IconTextLink;
