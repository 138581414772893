import IMAGES from '../../assets/images';
import ImgWithFallback from '../UI/ImgWithFallback';
import { useTranslation } from 'react-i18next';

function FeaturesSection() {
  const { t } = useTranslation('translation', { keyPrefix: 'home.features' });

  return (
    <section className="features-section" id="features">
      <h2
        className="section-title"
        data-aos="fade-up"
        data-aos-offset="50"
        data-aos-delay="100"
      >
        {t('title')}
      </h2>
      <div className="features">
        <div className="feature" data-aos="fade-up" data-aos-duration="700">
          <div className="feature__img-box">
            <ImgWithFallback
              classes="feature__mockup"
              src={IMAGES.mockupHomeWebp}
              fallbackSrc={IMAGES.mockupHomePng}
              alt="Kuddly's home page"
            />
          </div>
          <div className="feature__text-box">
            <h3 className="feature__title">{t('feature_1.title')}</h3>
            <p className="feature__description">{t('feature_1.description')}</p>
          </div>
        </div>
        <div className="feature" data-aos="fade-up" data-aos-duration="700">
          <div className="feature__img-box">
            <ImgWithFallback
              classes="feature__mockup"
              src={IMAGES.mockupPetCategoriesWebp}
              fallbackSrc={IMAGES.mockupPetCategoriesPng}
              alt="Pet categories page. Includes dogs, cats, birds, reptiles, rodents and fish."
            />
          </div>
          <div className="feature__text-box">
            <h3 className="feature__title">{t('feature_2.title')}</h3>
            <p className="feature__description">{t('feature_2.description')}</p>
          </div>
        </div>
        <div className="feature" data-aos="fade-up" data-aos-duration="700">
          <div className="feature__img-box">
            <ImgWithFallback
              classes="feature__mockup"
              src={IMAGES.mockupPetWebp}
              fallbackSrc={IMAGES.mockupPetPng}
              alt="Kuddly's pet post"
            />
          </div>
          <div className="feature__text-box">
            <h3 className="feature__title">{t('feature_3.title')}</h3>
            <p className="feature__description">{t('feature_3.description')}</p>
          </div>
        </div>
        <div className="feature" data-aos="fade-up" data-aos-duration="700">
          <div className="feature__img-box">
            <ImgWithFallback
              classes="feature__mockup"
              src={IMAGES.mockupOrgWebp}
              fallbackSrc={IMAGES.mockupOrgPng}
              alt="Kuddly's create organization page"
            />
          </div>
          <div className="feature__text-box">
            <h3 className="feature__title">{t('feature_4.title')}</h3>
            <p className="feature__description">{t('feature_4.description')}</p>
          </div>
        </div>
      </div>
      <div className="features__decoration--ellipse1">
        <img
          src={IMAGES.decoratorSideSvg}
          alt="Eclipse background decoration"
        />
      </div>
      <div className="features__decoration--ellipse2">
        <img
          src={IMAGES.decoratorSideSvg}
          alt="Eclipse background decoration"
        />
      </div>
      <div className="features__decoration--string">
        <img
          src={IMAGES.decoratorStringSvg}
          alt="String background decoration"
        />
      </div>
    </section>
  );
}

export default FeaturesSection;
