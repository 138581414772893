import { useContext } from 'react';
import AnimateHeight from 'react-animate-height';
import { FaqContext } from '../../store/faqContext';

import Icon from '../UI/Icon';

const showIcon = <Icon icon="add" name="add" classes="faq__question--icon" />;
const hideIcon = (
  <Icon icon="remove" name="remove" classes="faq__question--icon" />
);

function Faq({ id, question, answer, aosDuration }) {
  const { activeFaq, setActiveFaq } = useContext(FaqContext);

  const isExpanded = activeFaq === id;

  const answerHeight = isExpanded ? 'auto' : 0;

  const toggleFaq = () => {
    if (isExpanded) setActiveFaq(null);
    if (!isExpanded) setActiveFaq(id);
  };

  return (
    <div className="faq__wrapper">
      <button
        className="faq__question"
        type="button"
        onClick={toggleFaq}
        data-aos="fade-up"
        data-aos-offset="50"
        data-aos-delay="150"
        data-aos-duration={aosDuration}
      >
        {!isExpanded && showIcon}
        {isExpanded && hideIcon}

        {question}
      </button>
      <AnimateHeight
        className="answerHeightAnimation"
        duration={500}
        height={answerHeight}
        animateOpacity="true"
        style={{ transition: 'box-shadow 500ms, margin-top 500ms' }}
      >
        <p id={id} className="faq__answer">
          {answer}
        </p>
      </AnimateHeight>
    </div>
  );
}

export default Faq;
