import IMAGES from '../../assets/images';

function Footer() {
  return (
    <footer className="footer">
      <img src={IMAGES.logoSvg} alt="Kuddly logo" className="footer__logo" />
      <p className="footer__text">
        Copyright &copy; {new Date().getFullYear()} all rights reserved by
        Kuddly
      </p>
    </footer>
  );
}

export default Footer;
