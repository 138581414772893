const IMAGES = {
  mockupPetWebp: require('./mockups/mockup-pet.webp'),
  mockupPetPng: require('./mockups/mockup-pet.png'),
  mockupPetCategoriesWebp: require('./mockups/mockup-pet-categories.webp'),
  mockupPetCategoriesPng: require('./mockups/mockup-pet-categories.png'),
  mockupHomeWebp: require('./mockups/mockup-home.webp'),
  mockupHomePng: require('./mockups/mockup-home.png'),
  mockupOrgWebp: require('./mockups/mockup-org.webp'),
  mockupOrgPng: require('./mockups/mockup-org.png'),
  mockupSplashWebp: require('./mockups/mockup-splash.webp'),
  mockupSplashPng: require('./mockups/mockup-splash.png'),
  downloadAppleSvg: require('./icons/download-apple.svg').default,
  downloadAndroidSvg: require('./icons/download-google-play.svg').default,
  logoSvg: require('./icons/logo.svg').default,
  flagGrPng: require('./icons/flag-gr.png'),
  flagUkPng: require('./icons/flag-uk.png'),
  decoratorLineSvg: require('./decoration/dashed-line.svg').default,
  decoratorCornerSvg: require('./decoration/decorator-corner.svg').default,
  decoratorSideSvg: require('./decoration/decorator-side.svg').default,
  decoratorStringSvg: require('./decoration/decorator-string.svg').default,
  illustration404: require('./decoration/illustration-404.svg').default,
  illustrationResetPassword:
    require('./decoration/illustration-reset-password.svg').default,
};

export default IMAGES;
