import { useState } from 'react';
import useScrollHighlight from '../hooks/useScrollHighlight';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Header from '../components/Layout/Header';
import HeaderSectionLink from '../components/Layout/HeaderSectionLink';
import HeroSection from '../components/Home/HeroSection';
import FeaturesSection from '../components/Home/FeaturesSection';
import FaqSection from '../components/Home/FaqSection';
import JoinSection from '../components/Home/JoinSection';
import ContactSection from '../components/Home/ContactSection';
import Footer from '../components/Layout/Footer';
import BtnBackToTop from '../components/UI/BackToTopBtn';

function Home() {
  const { t } = useTranslation('translation', { keyPrefix: 'home' });

  const [heroIsVisible, setHeroIsVisible] = useState(null);
  const changeHeroVisibility = bool => {
    setHeroIsVisible(bool);
  };

  const ids = ['home', 'features', 'faq', 'download', 'contact'];
  const activeSection = useScrollHighlight({ ids, offset: 200 });

  const navLinks = (
    <>
      <HeaderSectionLink
        name="home"
        text={t('nav.home')}
        activeSection={activeSection}
      />
      <HeaderSectionLink
        name="features"
        text={t('nav.features')}
        activeSection={activeSection}
      />
      <HeaderSectionLink
        name="faq"
        text={t('nav.faq')}
        activeSection={activeSection}
      />
      <HeaderSectionLink
        name="download"
        text={t('nav.download')}
        activeSection={activeSection}
      />
      <HeaderSectionLink
        name="contact"
        text={t('nav.contact')}
        activeSection={activeSection}
      />
      <HeaderSectionLink
        name="privacy"
        text={t('nav.privacy')}
        activeSection={activeSection}
      />
    </>
  );

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('description')} />
      </Helmet>
      <Header navLinks={navLinks} heroIsVisible={heroIsVisible} />
      <HeroSection onHeroVisibilityChange={changeHeroVisibility} />
      <FeaturesSection />
      <FaqSection />
      <div className="contact-wrapper">
        <JoinSection />
        <ContactSection />
      </div>
      <Footer />
      <BtnBackToTop heroIsVisible={heroIsVisible} />
    </>
  );
}

export default Home;
