import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HeaderSmall from '../components/Layout/HeaderSmall';
import Footer from '../components/Layout/Footer';
import IMAGES from '../assets/images';

function NotFound() {
  const { t } = useTranslation('translation', { keyPrefix: 'notFound' });

  return (
    <>
      <HeaderSmall />

      <section className="error-section">
        <div className="error-container">
          <h1 className="error__title">{t('title')}</h1>
          <p className="error__description">{t('description')}</p>
          <Link to="/" className="error__btn">
            {t('button')}
          </Link>
        </div>
        <div className="illustration">
          <img
            className="illustration__img"
            src={IMAGES.illustration404}
            alt="404 not found illustration"
          />
        </div>
      </section>
      <Footer />
    </>
  );
}

export default NotFound;
