import { useRef, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import IMAGES from '../../assets/images';
import HeroMockups from '../UI/HeroMockups';
import DownloadLinks from './DownloadLinks';

function HeroSection({ onHeroVisibilityChange }) {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'home.hero',
  });

  const heroRef = useRef(null);

  const options = useMemo(() => {
    return { root: null, threshold: 0, rootMargin: '-1px' };
  }, []);

  useEffect(() => {
    const observerCallback = entries => {
      const [entry] = entries;
      onHeroVisibilityChange(entry.isIntersecting);
    };

    const observer = new IntersectionObserver(observerCallback, options);
    const target = heroRef.current;
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [heroRef, options, onHeroVisibilityChange]);

  return (
    <section className="hero-section" id="home" ref={heroRef}>
      <div className="hero">
        <h1
          className="hero__title"
          data-aos="fade-right"
          data-aos-offset="50"
          lang={i18n.language || window.localStorage.i18nextLng || ''}
        >
          {t('title')}
        </h1>
        <p className="hero__text" data-aos="fade-right" data-aos-offset="50">
          {t('description')}
        </p>

        <DownloadLinks
          classes="hero__downloads"
          aos={{ type: 'fade-right', offset: '50' }}
        />

        <HeroMockups divClass="hero__mockups" imgClass="hero__mockup" />
      </div>
      <div className="hero__decoration">
        <img
          src={IMAGES.decoratorCornerSvg}
          alt="Eclipse background decoration"
        />
      </div>
    </section>
  );
}

export default HeroSection;
