import { useTranslation } from 'react-i18next';

import IMAGES from '../../assets/images';
import ImgWithFallback from '../UI/ImgWithFallback';
import DownloadLinks from './DownloadLinks';

function JoinSection() {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'home.download',
  });
  return (
    <section className="join-us-section" id="download">
      <div className="join" data-aos="fade-up">
        <h2
          className="join__title"
          lang={i18n.language || window.localStorage.i18nextLng || ''}
        >
          {t('title')}
        </h2>
        <p className="join__text">{t('description')}</p>

        <DownloadLinks classes="join__downloads" />
      </div>

      <ImgWithFallback
        classes="join__img-box"
        src={IMAGES.mockupSplashWebp}
        fallbackSrc={IMAGES.mockupSplashPng}
        alt="Kuddly's splash screen"
        imgClasses="join__mockup"
        aos={{ type: 'zoom-in-up', offset: 225 }}
      />
      <img
        src={IMAGES.decoratorLineSvg}
        alt="Dashed line background decoration"
        className="join__line"
      />
    </section>
  );
}

export default JoinSection;
