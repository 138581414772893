import { useEffect, useState } from 'react';
import Icon from './Icon';

function BtnBackToTop({ heroIsVisible }) {
  const backToTopHandler = e => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [btnClasses, setBtnClasses] = useState('btn__back-to-top');

  useEffect(() => {
    if (heroIsVisible === true) {
      if (btnClasses.includes('fadeInBottom'))
        setBtnClasses('btn__back-to-top fadeOutBottom');
    } else if (heroIsVisible === false) {
      setBtnClasses('btn__back-to-top fadeInBottom');
    }
  }, [btnClasses, heroIsVisible]);

  return (
    <button
      id="backToTop"
      className={btnClasses}
      aria-label="Go to top of the page"
      onClick={backToTopHandler}
    >
      <Icon classes="btn__back-to-top--icon" icon="chevron-up" />
    </button>
  );
}

export default BtnBackToTop;
