import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useInput from '../../hooks/useInput';
import Icon from '../UI/Icon';

function Form() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'home.contact.form',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loaderText, setLoaderText] = useState('');
  const [loaderCheckboxValue, setLoaderCheckboxValue] = useState(false);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
  } = useInput(value => value.trim() !== '');

  const isEmail = function (emailAdress) {
    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return !!emailAdress.match(regex);
  };

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(isEmail);

  const isMessage = function (message) {
    let remText = message.replace(/\s/g, '');
    let lenText = remText.length;
    return lenText >= 10;
  };

  const {
    value: enteredMessage,
    isValid: enteredMessageIsValid,
    hasError: messageHasError,
    valueChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
  } = useInput(isMessage);

  const formIsValid =
    enteredNameIsValid && enteredEmailIsValid && enteredMessageIsValid;

  const formSubmitHandler = e => {
    e.preventDefault();
    if (!formIsValid) return;
    submitForm();
  };

  function submitForm() {
    setIsLoading(true);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fullName: enteredName,
        senderEmail: enteredEmail,
        message: enteredMessage,
      }),
    };

    fetch('https://services.kuddly.app:9095/api/v1/emails/contact', options)
      .then(response => {
        if (response.status === 200) {
          formSubmitted(true, 'Message Successfully Sent');
          dataLayerPush(true, response.status, null);
        } else {
          formSubmitted(false, 'Something went wrong');
          dataLayerPush(false, response.status, null);
        }
      })
      .catch(err => {
        setTimeout(function () {
          formSubmitted(false, 'Something went wrong. Try again later.');
          dataLayerPush(false, null, err.message);
        }, 700);
      });
  }

  function formSubmitted(submitted, message) {
    setLoaderText(message);
    setLoaderCheckboxValue(true);

    if (submitted) setSubmittedSuccessfully(true);
  }

  function dataLayerPush(
    submittedSuccessfully = false,
    responseStatus = null,
    error = null
  ) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'form_submission',
      formType: 'Contact us',
      submittedSuccessfully: submittedSuccessfully,
      responseStatus: responseStatus,
      error: error,
    });
  }

  return (
    <form
      className="form"
      action=""
      onSubmit={formSubmitHandler}
      data-aos="fade-up"
      data-aos-offset="100"
    >
      <div className={`form__group ${isLoading ? 'hidden' : ''}`}>
        <label htmlFor="name" className="form__label">
          <Icon icon="id" />
        </label>
        <input
          type="text"
          id="name"
          className="form__input"
          placeholder={t('name_input')}
          value={enteredName}
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
        />
      </div>
      <div className={`form__group ${isLoading ? 'hidden' : ''}`}>
        <label htmlFor="email" className="form__label">
          <Icon icon="envelope" />
        </label>
        <input
          type="email"
          id="email"
          className="form__input"
          placeholder={t('email_input')}
          value={enteredEmail}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
        />
      </div>
      <div className={`form__group ${isLoading ? 'hidden' : ''}`}>
        <label htmlFor="message" className="form__label">
          <Icon icon="message" />
        </label>
        <textarea
          id="message"
          className="form__input"
          name="message"
          placeholder={t('message_input')}
          value={enteredMessage}
          onChange={messageChangeHandler}
          onBlur={messageBlurHandler}
        ></textarea>
      </div>
      <div className="form__group--btn">
        <ul className="form__errors-list">
          {nameHasError && <li className="form__error">{t('error_name')}</li>}
          {emailHasError && <li className="form__error">{t('error_email')}</li>}
          {messageHasError && (
            <li className="form__error">{t('error_message')}</li>
          )}
        </ul>
        <button className={`form__btn ${isLoading ? 'hidden' : ''}`}>
          {t('submit_button')}
        </button>
      </div>

      {isLoading && (
        <div className="form-loader">
          <input
            className="form-loader__input"
            type="checkbox"
            id="check"
            checked={loaderCheckboxValue}
            readOnly
          />
          <label className="form-loader__label" htmlFor="check">
            {submittedSuccessfully && <div className="success-icon"></div>}
            {!submittedSuccessfully && <div className="error-icon"></div>}
          </label>
          <p className="form-loader__message">{loaderText}</p>
        </div>
      )}
    </form>
  );
}

export default Form;
