import { useTranslation } from 'react-i18next';

import IMAGES from '../../assets/images';
import HeroMockups from '../UI/HeroMockups';
import DownloadLinks from '../Home/DownloadLinks';

function DonwloadSection() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'home.hero',
  });
  return (
    <section className="downlaod-section">
      <div className="download">
        <div className="download__text-box" data-aos="fade-right">
          <h1 className="download__title">{t('title')}</h1>

          <p className="hero__text" data-aos="fade-right" data-aos-offset="50">
            {t('description')}
          </p>

          <DownloadLinks
            classes="hero__downloads"
            aos={{ type: 'fade-right', offset: '50' }}
          />
        </div>

        <HeroMockups divClass="download__mockups" imgClass="download__mockup" />
      </div>
      <div className="hero__decoration">
        <img
          src={IMAGES.decoratorCornerSvg}
          alt="Eclipse background decoration"
        />
      </div>
    </section>
  );
}

export default DonwloadSection;
