import { useTranslation } from 'react-i18next';

import Form from './Form';
import Icon from '../UI/Icon';
import IconTextLink from '../UI/IconTextLink';

function ContactSection() {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'home.contact.contact_us',
  });

  return (
    <section className="contact-section" id="contact">
      <Form />

      <div className="contact" data-aos="fade-up">
        <h2
          className="contact__title"
          lang={i18n.language || window.localStorage.i18nextLng || ''}
        >
          {t('title')}
        </h2>
        <p className="contact__text">{t('description')}</p>
        <div className="contact__links">
          <a
            href="https://www.linkedin.com/company/kuddly-app/"
            className="contact__link"
            name="linkedin"
            aria-label="Kuddly's LinkedIn page"
            target="_blank"
            rel="noreferrer"
          >
            <Icon classes="contact__link--icon" icon="linkedin" />
          </a>

          <IconTextLink
            linkClass="contact__link"
            linkHref="mailto:info@kuddly.app"
            linkText="info@kuddly.app"
            iconClass="contact__link--icon"
            iconId="mail"
          />
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
