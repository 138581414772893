import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import HeaderSmall from '../components/Layout/HeaderSmall';
import Footer from '../components/Layout/Footer';
import BtnBackToTop from '../components/UI/BackToTopBtn';

function Privacy() {
  const { t } = useTranslation('translation');
  const [heroIsVisible, setHeroIsVisible] = useState(true);
  window.addEventListener('scroll', () => {
    setHeroIsVisible(window.scrollY < 400);
  });

  return (
    <>
      <HeaderSmall heroIsVisible={heroIsVisible} />
      <section className="privacy-section">
        <div className="privacy__title-container">
          <h1 className="privacy__title-primary">{t('privacy.title')}</h1>
          <p className="privacy__last-updated">
            {t('privacy.updated')} {t('privacy.date')}
          </p>
        </div>

        <p className="privacy__description">{t('privacy.description')}</p>
        <h2 className="privacy__title-primary">
          {t('privacy.interpretationDefinitions.title')}
        </h2>
        <h3 className="privacy__title-secondary">
          {t('privacy.interpretationDefinitions.interpretation.title')}
        </h3>
        <p className="privacy__description">
          {t('privacy.interpretationDefinitions.interpretation.description')}
        </p>
        <h3 className="privacy__title-secondary">
          {t('privacy.interpretationDefinitions.definitions.title')}
        </h3>
        <p className="privacy__description">
          {t('privacy.interpretationDefinitions.definitions.description')}
        </p>
        <ul className="privacy__list">
          {t('privacy.interpretationDefinitions.definitions.list', {
            returnObjects: true,
          }).map((item, i) => (
            <li key={`definitions-${i}`} className="privacy__list-item">
              <Trans i18nKey={item} components={[<strong />, <em />]} />
            </li>
          ))}
        </ul>
        <h2 className="privacy__title-primary">
          {t('privacy.dataCollectionUsage.title')}
        </h2>
        <h3 className="privacy__title-secondary">
          {t('privacy.dataCollectionUsage.types.title')}
        </h3>
        <h4 className="privacy__title-tertiary">
          {t('privacy.dataCollectionUsage.types.personal.title')}
        </h4>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.types.personal.description')}
        </p>
        <ul className="privacy__list">
          {t('privacy.dataCollectionUsage.types.personal.list', {
            returnObjects: true,
          }).map((item, i) => (
            <li key={`personal-${i}`} className="privacy__list-item">
              <Trans i18nKey={item} components={[<strong />, <em />]} />
            </li>
          ))}
        </ul>
        <h4 className="privacy__title-tertiary">
          {t('privacy.dataCollectionUsage.types.usage.title')}
        </h4>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.types.usage.description')}
        </p>
        <h4 className="privacy__title-tertiary">
          {t('privacy.dataCollectionUsage.types.information.title')}
        </h4>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.types.information.description1')}
        </p>
        <ul className="privacy__list">
          {t('privacy.dataCollectionUsage.types.information.list', {
            returnObjects: true,
          }).map((item, i) => (
            <li key={`information-${i}`} className="privacy__list-item">
              <Trans i18nKey={item} components={[<strong />, <em />]} />
            </li>
          ))}
        </ul>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.types.information.description2')}
        </p>
        <h3 className="privacy__title-secondary">
          {t('privacy.dataCollectionUsage.use.title')}
        </h3>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.use.description1')}
        </p>
        <ul className="privacy__list">
          {t('privacy.dataCollectionUsage.use.list1', {
            returnObjects: true,
          }).map((item, i) => (
            <li key={`usage-a-${i}`} className="privacy__list-item">
              <Trans i18nKey={item} components={[<strong />, <em />]} />
            </li>
          ))}
        </ul>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.use.description2')}
        </p>
        <ul className="privacy__list">
          {t('privacy.dataCollectionUsage.use.list2', {
            returnObjects: true,
          }).map((item, i) => (
            <li key={`usage-b-${i}`} className="privacy__list-item">
              <Trans i18nKey={item} components={[<strong />, <em />]} />
            </li>
          ))}
        </ul>
        <h3 className="privacy__title-secondary">
          {t('privacy.dataCollectionUsage.retention.title')}
        </h3>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.retention.description')}
        </p>
        <h3 className="privacy__title-secondary">
          {t('privacy.dataCollectionUsage.transfer.title')}
        </h3>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.transfer.description')}
        </p>
        <h3 className="privacy__title-secondary">
          {t('privacy.dataCollectionUsage.delete.title')}
        </h3>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.delete.description')}
        </p>
        <h3 className="privacy__title-secondary">
          {t('privacy.dataCollectionUsage.disclosure.title')}
        </h3>
        <h4 className="privacy__title-tertiary">
          {t('privacy.dataCollectionUsage.disclosure.business.title')}
        </h4>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.disclosure.business.description')}
        </p>
        <h4 className="privacy__title-tertiary">
          {t('privacy.dataCollectionUsage.disclosure.law.title')}
        </h4>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.disclosure.law.description')}
        </p>
        <h4 className="privacy__title-tertiary">
          {t('privacy.dataCollectionUsage.disclosure.other.title')}
        </h4>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.disclosure.other.description')}
        </p>
        <ul className="privacy__list">
          {t('privacy.dataCollectionUsage.disclosure.other.list', {
            returnObjects: true,
          }).map((item, i) => (
            <li key={`disclosure-${i}`} className="privacy__list-item">
              <Trans i18nKey={item} components={[<strong />, <em />]} />
            </li>
          ))}
        </ul>
        <h3 className="privacy__title-secondary">
          {t('privacy.dataCollectionUsage.security.title')}
        </h3>
        <p className="privacy__description">
          {t('privacy.dataCollectionUsage.security.description')}
        </p>
        <h2 className="privacy__title-primary">
          {t('privacy.googleSignIn.title')}
        </h2>
        <p className="privacy__description">
          {t('privacy.googleSignIn.description')}
        </p>
        <h3 className="privacy__title-secondary">
          {t('privacy.googleSignIn.privacyAndDataSecurity.title')}
        </h3>
        <ul className="privacy__list">
          {t('privacy.googleSignIn.privacyAndDataSecurity.list', {
            returnObjects: true,
          }).map((item, i) => (
            <li key={`google-privacy-and-data-${i}`} className="privacy__list-item">
              <Trans i18nKey={item} components={[<strong />, <em />]} />
            </li>
          ))}
        </ul>
        <h3 className="privacy__title-secondary">
          {t('privacy.googleSignIn.userConsent.title')}
        </h3>
        <ul className="privacy__list">
          {t('privacy.googleSignIn.userConsent.list', {
            returnObjects: true,
          }).map((item, i) => (
            <li key={`google-user-consent-${i}`} className="privacy__list-item">
              <Trans i18nKey={item} components={[<strong />, <em />]} />
            </li>
          ))}
        </ul>
        <h3 className="privacy__title-secondary">
          {t('privacy.googleSignIn.accountSecurity.title')}
        </h3>
        <ul className="privacy__list">
          {t('privacy.googleSignIn.accountSecurity.list', {
            returnObjects: true,
          }).map((item, i) => (
            <li key={`google-account-security-${i}`} className="privacy__list-item">
              <Trans i18nKey={item} components={[<strong />, <em />]} />
            </li>
          ))}
        </ul>
        <h3 className="privacy__title-secondary">
          {t('privacy.googleSignIn.complianceWithGooglePolicies.title')}
        </h3>
        <ul className="privacy__list">
          {t('privacy.googleSignIn.complianceWithGooglePolicies.list', {
            returnObjects: true,
          }).map((item, i) => (
            <li key={`google-compliance-with-policies-${i}`} className="privacy__list-item">
              <Trans i18nKey={item} components={[<strong />, <em />]} />
            </li>
          ))}
        </ul>
        <p className="privacy__description">
          {t('privacy.googleSignIn.summary')}
        </p>
        <h2 className="privacy__title-primary">
          {t('privacy.childrenPrivacy.title')}
        </h2>
        <p className="privacy__description">
          {t('privacy.childrenPrivacy.description')}
        </p>
        <h2 className="privacy__title-primary">{t('privacy.links.title')}</h2>
        <p className="privacy__description">{t('privacy.links.description')}</p>
        <h2 className="privacy__title-primary">{t('privacy.changes.title')}</h2>
        <p className="privacy__description">
          {t('privacy.changes.description')}
        </p>
        <h2 className="privacy__title-primary">{t('privacy.contact.title')}</h2>
        <p className="privacy__description">
          {t('privacy.contact.description')}
        </p>
        <ul className="privacy__list">
          <li key="link-email" className="privacy__list-item">
            {t('privacy.contact.email')}
            <a className="privacy__link" href="mailto:info@kuddly.app">
              info@kuddly.app
            </a>
          </li>
          <li key="link-website" className="privacy__list-item">
            {t('privacy.contact.website')}
            <Link className="privacy__link" to="/">
              {t('privacy.contact.page')}
            </Link>
          </li>
        </ul>
      </section>
      <Footer />
      <BtnBackToTop heroIsVisible={heroIsVisible} />
    </>
  );
}

export default Privacy;
