function HeaderSectionLink(props) {
  return (
    <li>
      <a
        className={`link nav__list--link ${
          props.name === props.activeSection ? 'active' : ''
        }`}
        href="/"
        onClick={e => {
          e.preventDefault();
          if (props.name === 'home') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          } else if (props.name === 'privacy') {
            window.open("/privacy");
          } else {
            let section = document.getElementById(props.name);
            section && section.scrollIntoView({ behavior: 'smooth' });
          }
        }}
        name={props.name}
      >
        {props.text}
      </a>
    </li>
  );
}

export default HeaderSectionLink;
