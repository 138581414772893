import { useTranslation } from 'react-i18next';

import IMAGES from '../../assets/images';
import Faq from './Faq';
import { FaqContextProvider } from '../../store/faqContext';

function FaqSection() {
  const { t } = useTranslation('translation', { keyPrefix: 'home.faq' });
  const FAQs = [
    { id: 'faq_1', question: t('faq_1.question'), answer: t('faq_1.answer') },
    { id: 'faq_2', question: t('faq_2.question'), answer: t('faq_2.answer') },
    { id: 'faq_3', question: t('faq_3.question'), answer: t('faq_3.answer') },
    { id: 'faq_4', question: t('faq_4.question'), answer: t('faq_4.answer') },
  ];
  let faqAosDuration = 600;

  return (
    <section className="faq-section" id="faq">
      <h2
        className="section-title"
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-delay="100"
      >
        {t('title')}
      </h2>

      <div className="faq">
        <FaqContextProvider>
          {FAQs.map(faq => {
            faqAosDuration += 50;
            return (
              <Faq
                key={faq.id}
                id={faq.id}
                question={faq.question}
                answer={faq.answer}
                aosDuration={faqAosDuration}
              />
            );
          })}
        </FaqContextProvider>
      </div>
      <div className="faq__decoration--ellipse">
        <img
          src={IMAGES.decoratorSideSvg}
          alt="Eclipse background decoration"
        />
      </div>
      <div className="faq__decoration--string">
        <img
          src={IMAGES.decoratorStringSvg}
          alt="String background decoration"
        />
      </div>
    </section>
  );
}

export default FaqSection;
