import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from './Header';

function HeaderSmall({ heroIsVisible = true }) {
  const { t } = useTranslation('translation', { keyPrefix: 'resetPassword' });

  const navLinks = (
    <>
      <li>
        <Link to="/" className="link nav__list--link">
          {t('nav.home')}
        </Link>
      </li>
      <li>
        <Link to="/download" className="link nav__list--link">
          {t('nav.download')}
        </Link>
      </li>
    </>
  );

  return (
    <Header
      classes="small"
      heroIsVisible={heroIsVisible}
      navLinks={navLinks}
    ></Header>
  );
}

export default HeaderSmall;
