import ImgWithFallback from './ImgWithFallback';
import IMAGES from '../../assets/images';

function HeroMockups({ divClass, imgClass }) {
  return (
    <div className={divClass}>
      <ImgWithFallback
        classes={imgClass}
        src={IMAGES.mockupPetWebp}
        fallback={IMAGES.mockupPetPng}
        alt="Kuddly's pet post"
        aos={{ type: 'fade-up', offset: -50, delay: 350 }}
      />
      <ImgWithFallback
        classes={imgClass}
        src={IMAGES.mockupHomeWebp}
        fallback={IMAGES.mockupHomePng}
        alt="Kuddly's home page"
        aos={{ type: 'fade-up', offset: -50, delay: 300 }}
      />
      <ImgWithFallback
        classes={imgClass}
        src={IMAGES.mockupSplashWebp}
        fallback={IMAGES.mockupSplashPng}
        alt="Kuddly's splash screen"
        aos={{ type: 'fade-up', offset: -50, delay: 250 }}
      />
    </div>
  );
}

export default HeroMockups;
