import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
import './index.css';
import Home from './pages/Home';
import Download from './pages/Download';
import NotFound from './pages/NotFound';
import ResetPassword from './pages/ResetPassword';
import Privacy from './pages/Privacy';

function App() {
  useEffect(() => {
    setTimeout(() => {
      const preloader = document.querySelector('.loader');
      preloader.style.display = 'none';

      AOS.init({
        offset: 150,
        delay: 200,
        duration: 600,
        easing: 'ease',
        once: true,
      });
    }, 200);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/download" element={<Download />} />
      <Route path="/reset-password/*" element={<ResetPassword />} />
      <Route path="/privacy/" element={<Privacy />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
