import IMAGES from '../../assets/images';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function KeyError({ keyExpired, keyConsumed }) {
  const { t } = useTranslation('translation', { keyPrefix: 'resetPassword' });

  let title = t('error.keyInvalid.title');
  let message = t('error.keyInvalid.description');
  let btn = t('error.keyInvalid.button');

  if (keyConsumed) {
    title = t('error.keyConsumed.title');
    message = t('error.keyConsumed.description');
    btn = t('error.keyConsumed.button');
  } else if (keyExpired) {
    title = t('error.keyExpired.title');
    message = t('error.keyExpired.description');
    btn = t('error.keyExpired.button');
  }

  return (
    <section className="error-section error--reset-password">
      <div className="error-container">
        <h1 className="error__title">{title}</h1>
        <p className="error__description">{message}</p>
        <Link to="/" className="error__btn">
          {btn}
        </Link>
      </div>
      <div className="illustration">
        <img
          className="illustration__img"
          src={IMAGES.illustrationResetPassword}
          alt="Cat illustration"
        />
      </div>
    </section>
  );
}

export default KeyError;
