import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import DonwloadSection from '../components/Download/DownloadSection';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';

function Download() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'download',
  });
  useEffect(() => {
    document.querySelector('body').classList.add('download-page');
    return () => {
      document.querySelector('body').classList.remove('download-page');
    };
  }, []);

  const navLinks = (
    <li>
      <NavLink to="/" className="link nav__list--link">
        {t('nav.back_link')}
      </NavLink>
    </li>
  );

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('description')} />
      </Helmet>
      <Header navLinks={navLinks} />
      <DonwloadSection />
      <Footer />
    </>
  );
}

export default Download;
