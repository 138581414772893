function ImgWithFallback({
  classes,
  src,
  fallbackSrc,
  type = 'image/webp',
  imgClasses,
  alt,
  aos = {},
}) {
  return (
    <picture
      className={classes}
      data-aos={'type' in aos && aos.type}
      data-aos-offset={'offset' in aos && aos.offset}
      data-aos-delay={'delay' in aos && aos.delay}
    >
      <source srcSet={src} type={type} />
      <img src={fallbackSrc} className={imgClasses} alt={alt} />
    </picture>
  );
}

export default ImgWithFallback;
