import { useState } from 'react';
import useInput from '../../hooks/useInput';
import { useTranslation } from 'react-i18next';

import IMAGES from '../../assets/images';
import Icon from '../UI/Icon';

function Form({ userId, token }) {
  const { t } = useTranslation('translation', { keyPrefix: 'resetPassword' });
  const [isLoading, setIsLoading] = useState(false);
  const [loaderText, setLoaderText] = useState('');
  const [loaderCheckboxValue, setLoaderCheckboxValue] = useState(false);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

  const isPassword = function (password) {
    return password.trim('').length > 5;
  };

  const {
    value: enteredPassword,
    isValid: enteredPasswordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInput(isPassword);

  const passwordsMatch = function (password) {
    return password === enteredPassword;
  };

  const {
    value: enteredConfirmPassword,
    isValid: enteredConfirmPasswordIsValid,
    hasError: confirmPasswordHasError,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
  } = useInput(passwordsMatch);

  const formIsValid = enteredPasswordIsValid && enteredConfirmPasswordIsValid;

  const formSubmitHandler = e => {
    e.preventDefault();
    if (!formIsValid) return;
    submitForm();
  };

  function submitForm() {
    setIsLoading(true);

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        unencryptedPassword: enteredPassword,
      }),
    };

    fetch(
      `https://services.kuddly.app:8080/api/v1/users/${userId}/reset-password-token/${token}`,
      options
    )
      .then(response => {
        if (response.status === 200) {
          formSubmitted(true, t('form.success_meesage'));
        } else {
          formSubmitted(false, t('form.error_message'));
        }
      })
      .catch(err => {
        setTimeout(function () {
          formSubmitted(false, t('form.error_message'));
        }, 700);
      });
  }

  function formSubmitted(submitted, message) {
    setLoaderText(message);
    setLoaderCheckboxValue(true);

    if (submitted) setSubmittedSuccessfully(true);
  }

  return (
    <section className="reset-password">
      <form
        className="form"
        action=""
        onSubmit={formSubmitHandler}
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-delay="100"
        data-aos-duration="500"
      >
        {!isLoading && (
          <>
            <img src={IMAGES.logoSvg} className="form__img" alt="Kuddly logo" />
            <h3 className="form__title">{t('form.title')}</h3>
          </>
        )}
        <div className={`form__group ${isLoading ? 'hidden' : ''}`}>
          <label htmlFor="name" className="form__label">
            <Icon icon="unlock" />
          </label>
          <input
            type="password"
            id="password"
            className="form__input"
            placeholder={t('form.password_input')}
            value={enteredPassword}
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
          />
        </div>
        <div className={`form__group ${isLoading ? 'hidden' : ''}`}>
          <label htmlFor="name" className="form__label">
            <Icon icon="lock" />
          </label>
          <input
            type="password"
            id="confirm-password"
            className="form__input"
            placeholder={t('form.confirm_password_input')}
            value={enteredConfirmPassword}
            onChange={confirmPasswordChangeHandler}
            onBlur={confirmPasswordBlurHandler}
          />
        </div>

        <div className="form__group--btn">
          <ul className="form__errors-list">
            {passwordHasError && (
              <li className="form__error">{t('form.error_password')}</li>
            )}
            {confirmPasswordHasError && (
              <li className="form__error">
                {t('form.error_confirm_password')}
              </li>
            )}
          </ul>
          <button className={`form__btn ${isLoading ? 'hidden' : ''}`}>
            {t('form.submit_button')}
          </button>
        </div>

        {isLoading && (
          <div className="form-loader">
            <input
              className="form-loader__input"
              type="checkbox"
              id="check"
              checked={loaderCheckboxValue}
              readOnly
            />
            <label className="form-loader__label" htmlFor="check">
              {submittedSuccessfully && <div className="success-icon"></div>}
              {!submittedSuccessfully && <div className="error-icon"></div>}
            </label>
            <p className="form-loader__message">{loaderText}</p>
          </div>
        )}
      </form>
      <div className="reset-password__decoration-eclipse">
        <img src={IMAGES.decoratorSideSvg} alt="Eclipse decoration" />
      </div>
      <div className="reset-password__decoration-string">
        <img src={IMAGES.decoratorStringSvg} alt="String decoration" />
      </div>
    </section>
  );
}

export default Form;
